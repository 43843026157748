import { keysToSnakeCase } from 'utils/toSnakeCase'
import {
    ConversionType,
    getConversionId,
    isConversionType,
} from './conversions'
import { TrackingEventCategory, TrackingEventName } from './events'

/* eslint-disable camelcase */
interface GTagEventParams {
    // @ts-ignore
    event_callback?: () => void
}

const devGTag = (
    type: string,
    eventName: string,
    eventParams: GTagEventParams
) => {
    eventParams?.event_callback && eventParams?.event_callback()
}

export const gtag = (typeof window !== 'undefined' && window?.gtag) || devGTag

const devFBQ = () => {}

const sendEventToFB = (typeof window !== 'undefined' && window?.fbq) || devFBQ

const getFBEventName = (
    eventName: TrackingEventName,
    eventCategory: TrackingEventCategory
): 'GASubmit' | 'GAEvent' => {
    if (
        eventName.toLowerCase().includes('attempt') ||
        eventName.toLowerCase().includes('fail')
    ) {
        return 'GAEvent'
    }

    return isConversionType(eventCategory) ? 'GASubmit' : 'GAEvent'
}

interface EventParams {
    eventCategory: TrackingEventCategory
    [key: string]: string
}

export const sendEvent = (
    eventName: TrackingEventName,
    eventParams: EventParams,
    callback?: () => void
): void => {
    const location =
        (typeof window !== 'undefined' && window?.location.pathname) ||
        'unknown-location'

    gtag('event', eventName, {
        ...keysToSnakeCase(eventParams),
        event_location: location,
        event_callback: callback,
    })

    const { eventCategory } = eventParams
    isConversionType(eventCategory) &&
        gtag('event', 'conversion', {
            send_to: `${process.env.GADS_ID}/Yj_6CKHN-fEBEOT559QD`,
            event_callback: callback,
        })

    isConversionType(eventCategory) &&
        gtag('event', 'GAValid', {
            event_category: 'GAValid',
            event_location: location,
            event_callback: callback,
        })

    sendEventToFB('trackCustom', getFBEventName(eventName, eventCategory), {
        goalCategory: eventCategory,
        goalAction: eventName,
    })
}

export const sendConversion = (
    conversionType: ConversionType,
    callback?: () => void
): void =>
    gtag('event', 'conversion', {
        send_to: `${process.env.GADS_ID}/${getConversionId(conversionType)}`,
        event_callback: callback,
    })

export const getCID = (): string => {
    // const ga = typeof window !== 'undefined' && window?.ga
    if (typeof window === 'undefined' || !window.ga) return ''

    const tracker = window.ga.getAll()[0]
    return tracker.get('clientId')
}

const pageLoadTime = Date.now()

const getCookie = (cookieName: string) => {
    var results = document.cookie.match(`(^|;) ?${cookieName}=([^;]*)(;|$)`)

    if (results) {
        return unescape(results[2])
    } else {
        return null
    }
}

const fbPixelId = process.env.GATSBY_FB_PIXEL_ID

export const getFBValidLink = () => {
    const { height, width } = window?.screen
    const fbp = getCookie('_fbp')

    return `https://www.facebook.com/tr/?id=${fbPixelId}&ev=GAValid&dl=${
        location?.href
    }&rl=&if=false&ts=${Date.now()}&sw=${width}&sh=${height}&v=2.9.27&r=stable&o=30&fbp=${fbp}&it=${pageLoadTime}&coo=false&rqm=GET`
}

export const getGaValidLink = (cid: string) => {
    return `https://clearline.com.ua/new/validationPage/?cid=${cid}`
}
