export const backgroundColors = {
    index: '#FFEDBF', // #e5f390
    promotion: '#F7DFD7',
    contact: '#FFEDBF', // #FFEDBF
    project: '#D3E4EF',
    projectDark: '#C3D6D4',
    footer: '#231F20',
    formPromo: '#EBEBEB',
    services: '#CBCCDF',
    vizualization: '#D3E4DF',
}
export const colors = {
    dark: '#231F20',
    white: '#fff',
    gray: '#B5B5B5',
    darkText: '#151414',
    accentText: '#E24C1A',
    viber: '#59267c',
    telegram: '#0088cc',
    whatsapp: '#25d366',
    insta: '#E1306C',
    facebook: '#1778F2',
    blue: '#5f7db5',
}
