export const displayWidth = {
    desktop: '1350px',
    tablet: '1024px',
    mobile: '320px',
}
export const containerWidth = {
    desktop: '1190px',
    tablet: '864px',
    mobile: '300px',
}
