const conversionTypes = [
    'ViberClick',
    'telegram',
    'whatsapp',
    'Phone',
    'PhoneClickCircle',
    'EmailClick',
    'FormExample',
    'Callback',
    'Topform',
    'Bottomform',
    'Middleform',
    'Commercial',
]
export type ConversionType = typeof conversionTypes[number]
export const isConversionType = (value: string): value is ConversionType =>
    conversionTypes.includes(value)

type Conversions = Record<ConversionType, string>

const devConversions: Conversions = {
    FormCommercialProposal: 'GAW3CM_O4t4BEMuHrp8C',
    FormPromoPageBottom: 'todo-define',
    FormIndexPageBottom: 'todo-define',
    FormServicesPageBottom: 'todo-define',
    CallBackButtonForm: 'todo-define',
    CallbackFromPromo: 'todo-define',
    CallbackFromProject: 'todo-define',
    CallbackFromWorks: 'todo-define',
    ViberClick: 'xs72CKLN4PUCEOT559QD',
    telegram: 'todo-define',
    whatsapp: 'todo-define',
    SocialIconFacebook: 'todo-define',
    SocialIconInstagram: 'todo-define',
    Phone: 'todo-define',
    EmailClick: 'todo-define',
}

const propConversions: Conversions = {
    FormCommercialProposal: 'todo-define',
    FormPromoPageBottom: 'todo-define',
    FormIndexPageBottom: 'todo-define',
    FormServicesPageBottom: 'todo-define',
    CallbackFromPromo: 'todo-define',
    CallbackFromProject: 'todo-define',
    CallbackFromWorks: 'todo-define',
    CallBackButtonForm: 'todo-define',
    ViberClick: 'xs72CKLN4PUCEOT559QD',
    telegram: 'todo-define',
    whatsapp: 'todo-define',
    SocialIconFacebook: 'todo-define',
    SocialIconInstagram: 'todo-define',
    Phone: 'todo-define',
    EmailClick: 'todo-define',
}

const conversions = {
    dev: devConversions,
    prod: propConversions,
}

// TODO: define dynamicly
const env = 'dev'

export const getConversionId = (conversionType: ConversionType) =>
    conversions[env][conversionType]
